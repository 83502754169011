import React, { useEffect, useState } from "react";
import DocumentsManagerTable from "../../../../../components/Tables/DocumentManager/DocumentManagerTable.component";
import "./styles.css";
import { setModal } from "../../../../../redux/modal/modal.actions";
import { connect, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ESignatureTable from "../../../../../components/Tables/ESignature/ESignatureTable.component";
import { setLoading } from "../../../../../Utils/modalMethods";
import { useSetAtom } from "jotai";
import {
  confirmationModalAtom,
  initialConfirmationModalState,
} from "../../../../../jotai/atoms.jotai";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPost } from "../../../../../Services/authenticated";

type Props = {
  name: string;
  documents: any[] | null;
  id: string | number | null;
  setModal: any;
  isSignatureScreen?: boolean;
  setEsignDocumentData?: () => void;
};

const Folder: React.FC<Props> = ({
  name,
  // documents,
  id,
  setModal,
  isSignatureScreen,
  setEsignDocumentData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [folderDocuments, setFolderDocuments] = useState<null | any[]>(null);
  const [hideSearch, setHideSearch] = useState(true);
  const setConfirmModal = useSetAtom(confirmationModalAtom);
  const { getAccessTokenSilently } = useAuth0();

  const { documents } = useSelector((state: any) => state.masterData);

  useEffect(() => {
    if (documents) {
      const filteredDocuments = documents.filter((doc: any) => {
        return doc.folder === id;
      });
      setFolderDocuments(filteredDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  useEffect(() => {
    if (window.sessionStorage.getItem("openFolders")) {
      const sessionFolders = window.sessionStorage.getItem("openFolders");
      if (sessionFolders) {
        const prevFolders = JSON.parse(sessionFolders);
        if (
          prevFolders.folders.length > 0 &&
          prevFolders.folders.includes(id)
        ) {
          setIsOpen(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSessionStorageFolder = (folderId: any) => {
    if (window.sessionStorage.getItem("openFolders")) {
      const sessionFolders = window.sessionStorage.getItem("openFolders");
      if (sessionFolders) {
        const prevFolders = JSON.parse(sessionFolders);
        prevFolders.folders.push(folderId);
        window.sessionStorage.setItem(
          "openFolders",
          JSON.stringify(prevFolders)
        );
      }
    } else {
      window.sessionStorage.setItem(
        "openFolders",
        JSON.stringify({ folders: [folderId] })
      );
    }
  };

  const removeSessionStorageFolder = (folderId: any) => {
    if (window.sessionStorage.getItem("openFolders")) {
      const sessionFolders = window.sessionStorage.getItem("openFolders");
      if (sessionFolders) {
        const prevFolders = JSON.parse(sessionFolders);
        const index = prevFolders.folders.indexOf(folderId);

        if (index > -1) {
          prevFolders.folders.splice(index, 1);
        }
        window.sessionStorage.setItem(
          "openFolders",
          JSON.stringify(prevFolders)
        );
      }
    }
  };

  const deleteFolder = async () => {
    setLoading(true);
    let docIdArray = [];
    if (folderDocuments && folderDocuments.length > 0) {
      docIdArray = folderDocuments.map((e) => e.documentId);
    }

    try {
      const accessToken = await getAccessTokenSilently();
      const deleter = await APIPost("/folders/delete-folder", accessToken, {
        documents: docIdArray,
        folderId: id,
      });
      if (deleter.folderDeleted) {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteFolder = (folderId: any) => {
    setConfirmModal({
      show: true,
      message: "Eliminar Carpeta",
      subMessage: `¿Estás seguro que deseas eliminar la carpeta "${name}"?`,
      disclaimer:
        '*Por seguridad, todos los documentos dentro de la carpeta se moverán a la carpeta "General (sin asignar)"',
      onConfirm: () => {
        removeSessionStorageFolder(folderId);
        deleteFolder();
        setConfirmModal(initialConfirmationModalState);
      },
      onClose: () => setConfirmModal(initialConfirmationModalState),
    });
  };

  return (
    <div className={`folder-wrapper folder-wrapper-is-open-${isOpen}`}>
      <div className="folder-box">
        <div
          className="flex folder-box-opener"
          onClick={() => {
            if (isOpen) {
              removeSessionStorageFolder(id);
            } else {
              setSessionStorageFolder(id);
            }
            setIsOpen((prev) => !prev);
          }}
        >
          {isOpen ? (
            <i className="far fa-folder-open folder-box-icon primary" />
          ) : (
            <i className="far fa-folder folder-box-icon primary" />
          )}
          {name}{" "}
          {folderDocuments && (
            <span className="folder-box-quantity">
              ({folderDocuments.length})
            </span>
          )}
        </div>
        {isOpen && (
          <div
            className="b-secondary b-secondary-hover folder-search-button"
            onClick={() => {
              setHideSearch((prev) => !prev);
            }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} /> <div>Buscar</div>
          </div>
        )}

        {id && (
          <div className="folder-button-wrapper">
            <div
              className="b-secondary b-secondary-hover folder-box-button"
              onClick={() =>
                setModal({
                  folderNameModal: {
                    show: true,
                    folder: {
                      folderName: name,
                      folderId: id,
                    },
                  },
                })
              }
            >
              <i className="fas fa-pencil-alt"></i>
            </div>
            <div
              className="b-primary b-primary-hover folder-box-button"
              onClick={() => handleDeleteFolder(id)}
            >
              <i className="fas fa-trash-alt"></i>
            </div>
          </div>
        )}
      </div>

      {isOpen && folderDocuments && !isSignatureScreen && (
        <div className="folder-table-wrapper">
          {folderDocuments.length > 0 && (
            <DocumentsManagerTable
              documents={folderDocuments}
              hideSearch={hideSearch}
            />
          )}
        </div>
      )}
      {isOpen && folderDocuments && isSignatureScreen && (
        <div className="folder-table-wrapper">
          {folderDocuments.length > 0 && (
            <ESignatureTable
              documents={folderDocuments}
              hideSearch={hideSearch}
              setEsignDocumentData={setEsignDocumentData}
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setModal,
};

export default connect(null, mapDispatchToProps)(Folder);
