import React, { useState, useRef, ChangeEvent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Typography,
  Slider,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import Resizer from "react-image-file-resizer";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostImageWithError } from "../../Services/authenticated";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setAllCompanyUsers } from "../../redux/slices/masterData";

const blockClosing = false;

interface ModalPicProps {
  updateAvatar: (imgSrc: string) => void;
  closeModal: () => void;
}

const AvatarEditorModal: React.FC<ModalPicProps> = ({
  updateAvatar,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { allCompanyUsers, user } = useSelector(
    (state: RootState) => state.masterData
  );
  const { getAccessTokenSilently } = useAuth0();
  const [image, setImage] = useState<string | null>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [scale, setScale] = useState<number>(1);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0.5,
    y: 0.5,
  });
  const [error, setError] = useState<string>("");

  const resizeFile = (file: Blob) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const uploadImage = async (image: string) => {
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const uploadProfileImg = await APIPostImageWithError(
        "/profile/upload-profile-image",
        accesToken,
        image
      );

      if (uploadProfileImg.success) {
        updateAvatar(image);

        const updatedCompanyUsers = allCompanyUsers.map((u) => {
          if (u.userId === user?.userId) {
            return { ...u, image };
          }
          return u;
        });
        dispatch(setAllCompanyUsers(updatedCompanyUsers));
        window.location.reload();
        closeModal();
      } else {
        setAppError(uploadProfileImg.message || "Error al subir la imagen.");
      }
    } catch (error) {
      console.error(error);
      setAppError("Hubo un problema al subir la imagen.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setError("");
    const file = e.target.files?.[0];
    if (file) {
      const img = new Image();
      img.onload = async () => {
        const { width, height } = img;
        if (width < 150 || height < 150) {
          setError("La imagen debe tener un mínimo de 150 x 150 píxeles");
          setImage(null);
        } else {
          setError("");
          const resizedImage = await resizeFile(file);
          setImage(resizedImage as string);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      let imgSrc = canvas.toDataURL("image/jpeg", 0.8);

      try {
        await uploadImage(imgSrc);
        updateAvatar(imgSrc);
        closeModal();
      } catch (error) {
        console.error("Error uploading the image", error);
        setError("Error al subir la imagen, por favor intenta de nuevo.");
      }
    }
  };

  return (
    <Dialog
      open={true}
      // onClose={closeModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          closeModal();
        }
      }}
      aria-labelledby="crop-image-dialog"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          bgcolor: colorPallette.white,
          color: "white",
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {!blockClosing && (
          <i
            className="far fa-window-close confirmation-modal-close"
            onClick={closeModal}
          ></i>
        )}
      </DialogTitle>
      <DialogContent>
        <Box p={0} display="flex" flexDirection="column" alignItems="center">
          {image ? (
            <>
              <AvatarEditor
                ref={editorRef}
                image={image}
                width={200}
                height={200}
                border={50}
                borderRadius={100}
                color={[255, 255, 255, 0.6]}
                scale={scale}
                position={position}
                onPositionChange={setPosition}
                rotate={0}
              />

              <Box mt={2} width="100%">
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  color={colorPallette.dark}
                >
                  Ajustar tamaño
                </Typography>
                <Slider
                  color="primary"
                  min={1}
                  max={2}
                  step={0.01}
                  value={scale}
                  onChange={(e, newScale) => setScale(newScale as number)}
                />
              </Box>
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={colorPallette.dark}
                sx={{ mb: 2 }}
              >
                Mi foto de Perfil
              </Typography>
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: colorPallette.primary,
                  color: colorPallette.white,
                  width: "100%",
                  "&:hover": {
                    backgroundColor: colorPallette.dark,
                  },
                }}
              >
                Elegir Foto <FontAwesomeIcon icon={faBolt} className="ml1" />
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
            </>
          )}
          {error && (
            <Typography color={colorPallette.lightRed} sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <Box display={image ? "flex" : "none"} justifyContent="center" pb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mr: 2 }}
        >
          Guardar
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancelar
        </Button>
      </Box>
    </Dialog>
  );
};

export default AvatarEditorModal;
