import { useEffect, useState } from "react";
import DocumentCard from "../DocumentCard/DocumentCard";
import { APIPostWithError } from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useHistory } from "react-router-dom";
import { Button, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getAndSetSheetDataByCompanyId } from "../../../Utils/getSheetData";
import { colorPallette } from "../../../Common/theme";
import { useSetAtom } from "jotai";
import {
  confirmationModalAtom,
  initialConfirmationModalState,
} from "../../../jotai/atoms.jotai";
import { useDispatch } from "react-redux";
import { setCompanyDocuments } from "../../../redux/slices/masterData";
import { Document } from "../../../Types/Document.types";
import { deleteAndRedirect } from "../../../Utils/DocumentGeneratorMethods";

import "./styles.css";

type Props = {
  companyDocuments: Document[];
};

// test commen

const DashboardEditSelector: React.FC<Props> = ({ companyDocuments }) => {
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeleteButtons, setShowDeleteButtons] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<any>(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const [displayCompanyDocuments, setDisplayCompanyDocuments] = useState<
    Document[] | null
  >(null);

  const setConfirmationAtom = useSetAtom(confirmationModalAtom);

  useEffect(() => {
    window.sessionStorage.removeItem("custom_document_name");
    setLoading(true);
    if (localStorage.getItem("currentCompanyLogo")) {
      setCompanyLogo(localStorage.getItem("currentCompanyLogo"));
    }

    if (companyDocuments) {
      const filteredDocuments = companyDocuments.filter(
        (doc: any) => doc.generatorVersion === "2.0"
      );
      const sortedDocuments = [...filteredDocuments].sort((a, b) =>
        a.documentName1.localeCompare(b.documentName1)
      );
      setDisplayCompanyDocuments(sortedDocuments);
    }

    setLoading(false);
  }, [companyDocuments, user]);

  const getDocumentData = async (
    dataPath: string,
    generatorVersion: string,
    sheetId: string
  ) => {
    setLoading(true);
    try {
      const accesToken = await getAccessTokenSilently();
      const doc = await APIPostWithError(
        "/company-docs/get-company-document",
        accesToken,
        { dataPath, generatorVersion }
      );
      if (generatorVersion && generatorVersion === "2.0") {
        if (doc) {
          localStorage.setItem(
            "generator-form-document",
            JSON.stringify(doc.document)
          );
          localStorage.setItem(
            "generator-form-data",
            JSON.stringify(doc.questions)
          );
          localStorage.setItem(
            "generator-form-sections",
            JSON.stringify(doc.sections)
          );
          setLoading(false);
          history.push("/internal-generator");
        }
        if (sheetId) {
          const companyData = await getAndSetSheetDataByCompanyId(
            sheetId,
            accesToken
          );
          if (companyData)
            localStorage.setItem(
              "companyData",
              JSON.stringify({ ...companyData, type: "sheetsDataBase" })
            );
        }
      }
    } catch {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const dispatch = useDispatch();

  const onDelete = async (id: string) => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const document = companyDocuments.find((doc: any) => {
        return doc.documentId === id;
      });
      if (!document) {
        setAppError();
        setConfirmationAtom(initialConfirmationModalState);
        return;
      }
      const documentToDelete = await APIPostWithError(
        "/company-docs/delete-company-document",
        accessToken,
        {
          documentId: document.documentId,
          companyId: document.companyId,
        }
      );
      if (documentToDelete.isDeleted) {
        setLoading(false);
        setConfirmationAtom(initialConfirmationModalState);
        const newCompanyDocuments = companyDocuments.filter((doc: any) => {
          return doc.documentId !== id;
        });
        dispatch(setCompanyDocuments(newCompanyDocuments));
      }
    } catch (e) {
      setConfirmationAtom(initialConfirmationModalState);
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAction = () => {
    if (isDeleting) {
      setIsDeleting(false);
      setShowDeleteButtons(false);
    } else {
      setIsDeleting(true);
      setShowDeleteButtons(true);
    }
  };

  const handleShowDeleteModal = (id: string, documentName: string) => {
    setConfirmationAtom({
      message: "Esta seguro que desea eliminar este documento?",
      subMessage: `${documentName}`,
      show: true,
      onConfirm: () => onDelete(id),
      disclaimer: "*No se podra recuperar una vez eliminado",
    });
    setIsDeleting(false);
    setShowDeleteButtons(false);
  };

  return (
    <div>
      {displayCompanyDocuments && (
        <div className="dashboard-select0r-wrapper animate">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
          >
            <p className="dashboard-message">
              <i className="fas fa-play secondary margin-r" />
              En esta sección podés editar las preguntas y documentos que se
              generan
            </p>
            <Box display={"flex"} gap={2}>
              <Button
                variant="contained"
                sx={{ px: 2 }}
                onClick={() =>
                  setConfirmationAtom({
                    message: "¿Está seguro que desea crear un nuevo documento?",
                    subMessage:
                      "Esto borrará cualquier progreso no guardado del documento actual y comenzará un nuevo documento desde cero.",
                    show: true,
                    onConfirm: () => {
                      deleteAndRedirect(history);
                    },
                    onClose: () =>
                      setConfirmationAtom(initialConfirmationModalState),
                  })
                }
              >
                Crear nuevo
                <FontAwesomeIcon style={{ marginLeft: "8px" }} icon={faPen} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  px: 2,
                  bgcolor: isDeleting
                    ? colorPallette.dark
                    : colorPallette.lightRed,
                }}
                onClick={handleDeleteAction}
              >
                {isDeleting ? "Cancelar" : "Eliminar"}
                {!isDeleting ? (
                  <FontAwesomeIcon
                    style={{ marginLeft: "8px" }}
                    icon={faTrash}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ marginLeft: "8px" }}
                    icon={faCancel}
                  />
                )}
              </Button>
            </Box>
          </Box>
          <div className="dashboard-selector">
            <div className="dashboard-selector-grid">
              {displayCompanyDocuments &&
                displayCompanyDocuments.length > 0 &&
                displayCompanyDocuments.map((doc, index) => {
                  return (
                    <Box display={"flex"} gap={2} width={"100%"} key={index}>
                      {showDeleteButtons && (
                        <Button
                          variant="text"
                          sx={{
                            px: 2,
                            color: colorPallette.lightRed,
                            height: "65px",
                          }}
                          onClick={() =>
                            handleShowDeleteModal(
                              doc.documentId,
                              doc.documentName
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      )}
                      <DocumentCard
                        title1={doc.documentName1 || ""}
                        title2={doc.documentName2 || ""}
                        onClick={() => {
                          localStorage.setItem(
                            "document-update-data",
                            JSON.stringify(doc)
                          );
                          localStorage.setItem(
                            "removeLogo",
                            String(doc.removeLogo)
                          );
                          getDocumentData(
                            doc.dataPath,
                            doc.generatorVersion,
                            doc.sheetId
                          );
                        }}
                        index={index}
                        logo={companyLogo}
                      />
                    </Box>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardEditSelector;
