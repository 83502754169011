import React, { useEffect, useState } from "react";
import CreatorField from "./CreatorField/CreatorField";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadWordS3 } from "../../../../Utils/S3Download";
import {
  modalOpen,
  setAppError,
  setLoading,
} from "../../../../Utils/modalMethods";
import {
  editDocument,
  editReduxDocument,
} from "../../../../Utils/documentMethods";
import { Box, Modal, Stack, TextareaAutosize, Typography } from "@mui/material";
import { colorPallette } from "../../../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faEdit,
  faFolderOpen,
  faSave,
} from "@fortawesome/free-regular-svg-icons";
import Image from "../../../../uiComponents/Image/Image";
import { getImageName } from "../../../../Utils/getImage";
import {
  faAngleLeft,
  faCancel,
  faCloudArrowDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AndesTooltip } from "../../../../uiComponents/AndesTooltip/AndesTooltip";
import { Controller, useForm } from "react-hook-form";
import { useAtomValue } from "jotai";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../../jotai/commonComponentAtom.jotai";

export const DocumentDetailModal: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [editor, setEditor] = useState<boolean>(false);

  const { currentDocument, showDocumentDetailModal } =
    useAtomValue(commonComponentAtom);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    if (currentDocument) {
      setValue("fileName", currentDocument?.fileName);
      setValue("description", currentDocument?.description ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument, setValue]);

  const closeModal = () => {
    resetCommonComponent();
  };

  const getDocument = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      await downloadWordS3(currentDocument, accessToken);
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const payload = {
      ...currentDocument,
      fileName: data.fileName,
      description: data.description ?? null,
      companyId: currentDocument?.companyId ?? "",
      currentDocumentId: currentDocument?.documentId ?? "",
      currentDocumentType: currentDocument?.documentType ?? "",
      format: currentDocument?.format ?? "",
      dateCreated: currentDocument?.dateCreated ?? "",
    };

    const accessToken = await getAccessTokenSilently();

    try {
      await editDocument(payload, accessToken, editReduxDocument);

      setCommonComponents({
        showDocumentDetailModal: true,
        currentDocument: payload,
      });

      setEditor(false);
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
    }
  });

  const handleOpenFolderModal = () => {
    setCommonComponents({
      showFolderModal: true,
      currentDocument: currentDocument,
    });
  };

  const formattedDate =
    currentDocument?.dateCreated && !isNaN(Number(currentDocument.dateCreated))
      ? new Date(Number(currentDocument.dateCreated)).toLocaleDateString(
          "es-ES",
          {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }
        )
      : "Fecha no disponible";

  return (
    <Modal open={showDocumentDetailModal} onClose={closeModal}>
      <Box sx={{ height: "100vh" }}>
        <Box
          sx={{
            width: "65%",
            maxWidth: "1200px",
            top: "20%",
            left: "50%",
            transform: "translateX(-50%)",
            bgcolor: colorPallette.tertiary,
            borderRadius: "9px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pb: 5,
          }}
        >
          <Box
            onClick={closeModal}
            sx={{
              fontSize: "1.4rem",
              color: colorPallette.dark,
              position: "absolute",
              top: 40,
              left: 40,
              cursor: "pointer",
              display: "flex",
              gap: 1,
              paddingX: 2,
              paddingY: 0.5,
              borderRadius: "5px",
              bgcolor: colorPallette.secondary,
              transition: "all 0.12s",

              "&:hover": {
                color: colorPallette.red,
              },

              "& .icon": {
                transition: "transform 0.2s",
              },
              "&:hover .icon": {
                transform: "translateX(-5px)",
                transition: "transform 0.12s",
              },
            }}
          >
            <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            <Typography>Cerrar</Typography>
          </Box>

          {currentDocument && <CreatorField document={currentDocument} />}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              pt: 10,
            }}
          >
            {currentDocument && (
              <Box
                sx={{
                  width: "98%",
                  maxWidth: "1300px",
                  mt: 0,
                  borderRadius: "8px",
                  pl: "20px",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ width: "50%", display: "flex", alignItems: "center" }}
                  >
                    <Image
                      name={getImageName(currentDocument?.format)}
                      style={{ width: "80px", height: "80px" }}
                    />
                    <Stack
                      sx={{
                        alignItems: "start",
                        ml: 2,
                        height: "106px",
                        pt: 3,
                      }}
                    >
                      <Typography fontSize={".8rem"} pb={0}>
                        Nombre del currentDocumento:{" "}
                      </Typography>
                      {editor ? (
                        <Controller
                          name="fileName"
                          control={control}
                          rules={{
                            required: "El nombre del documento es obligatorio.",
                          }}
                          render={({ field }) => (
                            <>
                              <TextareaAutosize
                                {...field}
                                style={{
                                  fontSize: "14px",
                                  borderColor: errors.fileName
                                    ? colorPallette.red
                                    : colorPallette.primary,
                                  color: colorPallette.dark,
                                  fontFamily: "Roboto",
                                  width: "350px",
                                  height: "38px",
                                  borderRadius: "9px",
                                  lineHeight: "3",
                                  paddingLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              />
                              {errors.fileName && (
                                <Typography
                                  color={colorPallette.red}
                                  fontSize={"0.8rem"}
                                >
                                  {typeof errors.fileName?.message === "string"
                                    ? errors.fileName.message
                                    : "Hubo un error"}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      ) : (
                        <Typography
                          fontSize={"1.3rem"}
                          fontWeight={"bold"}
                          mt={-0.5}
                        >
                          {currentDocument?.fileName || "Sin nombre"}
                        </Typography>
                      )}
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      width: "50%",
                      position: "relative",
                      paddingLeft: "30px",
                      top: "7px",
                      gap: 2,
                    }}
                  >
                    {!editor && (
                      <AndesTooltip
                        description="Descargar"
                        placement="top"
                        sx={{ width: "38px", height: "38px" }}
                      >
                        <Box
                          onClick={getDocument}
                          sx={{
                            marginRight: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            width: "38px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "1rem",
                            transform: "scale(1)",
                            transition: "all 0.12s",
                            position: "relative",
                            bgcolor: colorPallette.secondary,
                            "&:hover": {
                              transform: "scale(1.14)",
                              bgcolor: colorPallette.primary,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCloudArrowDown}
                              color={colorPallette.dark}
                            />
                          </Box>
                        </Box>
                      </AndesTooltip>
                    )}

                    <Box display={"flex"} sx={{ gap: 5 }}>
                      {!editor && (
                        <AndesTooltip
                          description="Editar"
                          placement="top"
                          sx={{
                            width: "38px",
                            height: "38px",
                          }}
                        >
                          <Box
                            onClick={() => setEditor(true)}
                            sx={{
                              marginRight: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "38px",
                              width: "38px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              fontSize: "1rem",
                              transform: "scale(1)",
                              transition: "all 0.12s",
                              position: "relative",
                              bgcolor: colorPallette.secondary,
                              "&:hover": {
                                transform: "scale(1.14)",
                                bgcolor: colorPallette.primary,
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                color={colorPallette.dark}
                              />
                            </Box>
                          </Box>
                        </AndesTooltip>
                      )}

                      {editor && (
                        <Box
                          onClick={() => {
                            onSubmit();
                          }}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            width: editor ? "150px" : "38px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "1rem",
                            transform: "scale(1)",
                            transition: "all 0.12s",
                            position: "relative",
                            bgcolor: colorPallette.primary,
                            ml: 3,
                            "&:hover": {
                              transform: "scale(1.02)",
                              bgcolor: colorPallette.dark,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faSave}
                              color={colorPallette.white}
                            />
                            {editor && (
                              <Typography color={colorPallette.white} pl={1}>
                                Guardar
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}

                      {editor && (
                        <Box
                          onClick={() => {
                            setEditor(false);
                            reset();
                          }}
                          sx={{
                            marginRight: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            width: editor ? "150px" : "38px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "1rem",
                            transform: "scale(1)",
                            transition: "all 0.12s",
                            position: "relative",
                            bgcolor: colorPallette.lightRed,

                            "&:hover": {
                              transform: "scale(1.02)",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCancel}
                              color={colorPallette.white}
                              style={{}}
                            />
                            {editor && (
                              <Typography color={colorPallette.white} pl={1}>
                                Cancelar
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>

                    {!editor && (
                      <AndesTooltip
                        description="Mover a"
                        placement="top"
                        sx={{ width: "38px", height: "38px" }}
                      >
                        <Box
                          onClick={handleOpenFolderModal}
                          sx={{
                            marginRight: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            width: "38px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "1rem",
                            transform: "scale(1)",
                            transition: "all 0.12s",
                            position: "relative",
                            bgcolor: colorPallette.secondary,
                            "&:hover": {
                              transform: "scale(1.14)",
                              bgcolor: colorPallette.primary,
                            },
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFolderOpen}
                            color={colorPallette.dark}
                          />
                        </Box>
                      </AndesTooltip>
                    )}

                    {!editor && (
                      <AndesTooltip
                        description="Eliminar"
                        placement="top"
                        sx={{ width: "38px", height: "38px" }}
                      >
                        <Box
                          onClick={() => {
                            if (currentDocument)
                              modalOpen({
                                currentDocumentDetailModal: {
                                  show: false,
                                  currentDocument: null,
                                },
                                confirmModal: {
                                  show: true,
                                  message: "Confirme eliminación",
                                  submessage:
                                    "¿Seguro desea eliminar el currentDocumento seleccionado?",
                                  disclaimer:
                                    "*No se podrá deshacer, asegursese que no necesitará el currentDocumento en el futuro.",
                                  currentDocument: currentDocument,
                                  isAsync: true,
                                  confirmMethodName:
                                    "deleteDocumentAndReference",
                                  fileName: currentDocument.fileName,
                                },
                              });
                          }}
                          sx={{
                            marginRight: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            width: "38px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "1rem",
                            transform: "scale(1)",
                            transition: "all 0.12s",
                            position: "relative",
                            bgcolor: colorPallette.lightRed,
                            "&:hover": {
                              transform: "scale(1.14)",
                              bgcolor: colorPallette.strongRed,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              color={colorPallette.white}
                            />
                          </Box>
                        </Box>
                      </AndesTooltip>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "90%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "space-between",
                    pb: 0,
                    alignItems: "center",
                  }}
                >
                  <Box display={"flex"} gap={1} ml={1}>
                    <Typography>
                      {" "}
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        style={{ paddingRight: "10px" }}
                        color={colorPallette.secondary}
                      />
                      Tipo de currentDocumento:
                    </Typography>
                    <Typography fontWeight={"bold"}>
                      {currentDocument?.format}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={3}
                    alignItems={"center"}
                    paddingRight={19}
                  >
                    <Typography>
                      {" "}
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        style={{ paddingRight: "10px" }}
                        color={colorPallette.secondary}
                      />
                      Fecha de creación:{" "}
                    </Typography>
                    <Typography
                      fontWeight={"bold"}
                      py={0.5}
                      px={1}
                      fontSize={"0.9rem"}
                      bgcolor={colorPallette.primary}
                      borderRadius={"5px"}
                      color={colorPallette.white}
                    >
                      {formattedDate}
                    </Typography>
                  </Box>
                </Box>

                <Stack
                  sx={{
                    width: "100%",
                    borderRadius: "9px",
                    mt: 1,
                    ml: 1,
                    flexDirection: editor ? "column" : "row",
                  }}
                >
                  <Box
                    sx={{
                      width: "auto",
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: colorPallette.dark,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        style={{ paddingRight: "10px" }}
                        color={colorPallette.secondary}
                      />
                      Descripción:
                    </Typography>
                    {errors.description && (
                      <Typography color={colorPallette.red}>
                        {typeof errors.description?.message === "string"
                          ? errors.description.message
                          : "An error occurred"}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {editor ? (
                      <Controller
                        name="description"
                        control={control}
                        rules={{
                          maxLength: {
                            value: 80,
                            message:
                              "La descripción no debe tener más de 80 caracteres",
                          },
                        }}
                        render={({ field }) => (
                          <TextareaAutosize
                            {...field}
                            maxLength={80}
                            style={{
                              fontSize: "14px",
                              borderColor: colorPallette.primary,
                              color: colorPallette.dark,
                              fontFamily: "Roboto",
                              width: "700px",
                              height: "38px",
                              borderRadius: "9px",
                              lineHeight: "3",
                              paddingLeft: "10px",
                              marginTop: "15px",
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Typography
                        fontWeight={"bold"}
                        color={colorPallette.dark}
                        pl={1}
                      >
                        {currentDocument?.description === "null"
                          ? "Sin definir"
                          : currentDocument?.description}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
