import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../Form/FormInput";
import { useForm } from "react-hook-form";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../Services/authenticated";
import { useSetAtom } from "jotai";
import { userNameAtom } from "../../jotai/atoms.jotai";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CompanyUser, setAllCompanyUsers } from "../../redux/slices/masterData";
import { faRectangleXmark } from "@fortawesome/free-regular-svg-icons";

type EditProfileNameProps = {
  user: any;
  closeModal: () => void;
};

export const EditProfileNameModal: React.FC<EditProfileNameProps> = ({
  user,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { allCompanyUsers } = useSelector(
    (state: RootState) => state.masterData
  );
  const setUserName = useSetAtom(userNameAtom);
  const { getAccessTokenSilently } = useAuth0();
  const { control, handleSubmit } = useForm({
    defaultValues: { name: user.name || "" },
  });

  const handleSave = async (data: any) => {
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const updatedName = await APIPostWithError(
        "/profile/update-profile-name",
        accesToken,
        data
      );

      if (updatedName.success) {
        setUserName(data);
        setLoading(false);
        const updatedCompanyUsers = allCompanyUsers.map((u: CompanyUser) => {
          if (u.userId === user.userId) {
            return { ...u, name: data.name };
          }
          return u;
        });
        dispatch(setAllCompanyUsers(updatedCompanyUsers));
        closeModal();
      } else {
        setAppError(updatedName.message || "Error al actualizar el nombre.");
      }
    } catch (error) {
      console.error(error);
      setAppError("Hubo un problema al actualizar el nombre.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      aria-labelledby="crop-image-dialog"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          bgcolor: colorPallette.white,
          color: colorPallette.dark,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, pb: 1 }}>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: "30px",
            height: "30px",
          }}
        >
          <FontAwesomeIcon
            icon={faRectangleXmark}
            color={colorPallette.primary}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormInput
          label="Nombre"
          name="name"
          control={control}
          maxLength={30}
          required
          defaultValue={user.name}
        />
      </DialogContent>
      <Box justifyContent="center" mt={-2} p={3} pt={0}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleSave)}
          sx={{ width: "100%" }}
        >
          Guardar <FontAwesomeIcon icon={faBolt} className="ml1" />
        </Button>
      </Box>
    </Dialog>
  );
};
