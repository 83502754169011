import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { APIPostWithError } from "../../../../../Services/authenticated";
import {
  modalOpen,
  setAppError,
  setLoading,
} from "../../../../../Utils/modalMethods";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-regular-svg-icons";
import { useForm } from "react-hook-form";
import FormInput from "../../../../../uiComponents/Form/FormInput";
import { useAtomValue } from "jotai";
import {
  commonComponentAtom,
  resetCommonComponent,
} from "../../../../../jotai/commonComponentAtom.jotai";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { colorPallette } from "../../../../../Common/theme";

const SignerEmailModal: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { currentSigner, showSignerEmailModal } =
    useAtomValue(commonComponentAtom);
  const { control, handleSubmit, setError } = useForm({
    defaultValues: { email: currentSigner?.email || "" },
  });
  const { getAccessTokenSilently } = useAuth0();

  const updateSignerEmail = async (data: { email: string }) => {
    if (!data.email.includes("@") || !data.email.includes(".")) {
      setError("email", {
        type: "manual",
        message: "El email debe tener un formato válido.",
      });
      return;
    }

    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const res = await APIPostWithError(
        "/electronic-signature/update-signature",
        accessToken,
        {
          signerToken: currentSigner?.token,
          email: data.email,
        }
      );
      if (res.signatureUpdated) {
        await navigator.clipboard.writeText(currentSigner?.sign_url || "");
        modalOpen({
          successModal: {
            show: true,
            message: "Email actualizado exitosamente",
            submessage: "¡El enlace se ha copiado al portapapeles!",
            disclaimer: currentSigner?.sign_url,
          },
        });
        closeModal();
      } else {
        setAppError();
        closeModal();
      }
    } catch (error) {
      setErrorMessage("Error al actualizar el email.");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => resetCommonComponent();

  return (
    <Modal open={showSignerEmailModal} onClose={closeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          bgcolor: "white",
          width: "600px",
          borderRadius: "6px",
          p: "20px",
          pt: "40px",
          minHeight: "250px",
          my: "50xp",
        }}
      >
        <FontAwesomeIcon
          onClick={closeModal}
          icon={faRectangleXmark}
          style={{
            fontSize: "1.4rem",
            color: "rgb(170, 170, 228)",
            marginBottom: "10px",
            cursor: "pointer",
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        />
        <Stack>
          <Typography
            sx={{
              fontSize: "1.4rem",
              mb: "20px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            Modificar email del firmante
          </Typography>
          <FormInput
            label="Email"
            name="email"
            control={control}
            maxLength={30}
            required
          />
          {errorMessage && (
            <Typography color={colorPallette.red} fontSize="0.9rem" mb={"10px"}>
              *{errorMessage}
            </Typography>
          )}
          <Box
            component="button"
            type={"submit"}
            onClick={handleSubmit(updateSignerEmail)}
            sx={{
              width: "100%",
              bgcolor: colorPallette.secondary,
              border: "none",
              borderRadius: "9px",
              display: "flex",
              height: "60px",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              cursor: "pointer",
              transition: "all 0.12s",
              "&:hover": {
                bgcolor: colorPallette.tertiary,
              },
            }}
          >
            <FontAwesomeIcon color={colorPallette.dark} icon={faSave} />
            <Typography color={colorPallette.dark}>Guardar</Typography>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SignerEmailModal;
