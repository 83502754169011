import { Box } from "@mui/material";
import React from "react";
import { TutorialVideo } from "./TutorialVideo";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import TutorialPdfViewer from "./TutorialPdfViewer";
import pdfFirmaElectronica from "./InstructivoFirmaElectronica.pdf";

const Tutorials: React.FC = () => {
  const videoTutorials = [
    {
      title: "Generación de Contrato en Andes Docs",
      icon: "file-pen",
      src: "https://www.loom.com/embed/99d75a5e6c00450eb122ec00cb78352d?sid=92fcf5d1-1724-4190-8cea-564157619af0",
      message: "En este video aprenderás a crear contratos automatizados:",
    },
    {
      title: "Generación de Firma Electrónica en Andes Docs",
      icon: "signature",
      src: "https://www.loom.com/embed/22b41011ebe144a1bf0936232c493bc4?sid=15de300f-e9ee-421b-b36e-c9d489f10f8a",
      message:
        "En este video aprenderás a como firmar electrónicamente contratos automatizados:",
    },
    {
      title: "Creación de Usuario en Andes Docs",
      icon: "user",
      src: "https://www.loom.com/embed/ab04c9a34f6e48fd9d8152c8fa4325eb?sid=81533916-c8aa-4294-858d-308e54da4584",
      message: "En este video aprenderás a registrarte como usuario:",
    },
  ];

  const pdfTutorials = [
    {
      title: "Cómo hacer una Firma Electrónica en Andes Docs",
      icon: faPlayCircle,
      src: pdfFirmaElectronica,
      message:
        "En este documento aprenderás a como firmar electrónicamente contratos automatizados:",
    },
  ];

  return (
    <Box
      sx={{
        pt: 5,
        display: "flex",
        gap: "100px",
        flexDirection: "column",
        maxWidth: "700px",
      }}
      className="animate"
    >
      {videoTutorials.map((tutorial, index) => (
        <TutorialVideo
          key={index}
          title={tutorial.title}
          icon={tutorial.icon}
          message={tutorial.message}
          src={tutorial.src}
        />
      ))}
      {pdfTutorials.map((tutorial, index) => (
        <TutorialPdfViewer
          key={index}
          title={tutorial.title}
          icon={tutorial.icon}
          message={tutorial.message}
          src={tutorial.src}
        />
      ))}
    </Box>
  );
};

export default Tutorials;
