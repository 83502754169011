import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "react-loader-spinner";
import { APIPost } from "../../../../Services/authenticated";
import { Box, Modal, Stack, TextareaAutosize, Typography } from "@mui/material";
import { colorPallette } from "../../../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";
import { useForm, Controller } from "react-hook-form";
import { faRectangleXmark } from "@fortawesome/free-regular-svg-icons";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../../jotai/commonComponentAtom.jotai";

const NewFolderModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { showNewFolderModal, currentDocument } =
    useAtomValue(commonComponentAtom);

  const { getAccessTokenSilently } = useAuth0();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { folderName: "" },
  });

  const closeNewFolderModal = () => {
    resetCommonComponent();
    reset();
  };

  const onSaveFolder = async ({ folderName }: { folderName: string }) => {
    setIsLoading(true);
    const payload = { folderName };

    try {
      const accessToken = await getAccessTokenSilently();
      const folderSaved = await APIPost(
        "/folders/create-folder",
        accessToken,
        payload
      );

      if (folderSaved) {
        setCommonComponents({
          showFolderModal: true,
          currentDocument,
        });
        closeNewFolderModal();
      } else {
        window.location.reload();
      }
    } catch (e) {
      setIsLoading(false);
      // Mostrar mensaje de error
      console.error("Error al crear la carpeta:", e);
    }
  };

  return (
    <Modal open={showNewFolderModal} onClose={closeNewFolderModal}>
      <Stack
        sx={{
          bgcolor: colorPallette.white,
          position: "fixed",
          top: "7%",
          left: "50%",
          transform: "translateX(-50%)",
          width: "500px",
          borderRadius: "6px",
          padding: "20px",
        }}
      >
        <FontAwesomeIcon
          icon={faRectangleXmark}
          onClick={closeNewFolderModal}
          style={{
            fontSize: " 1.4rem",
            color: colorPallette.primary,
            marginBottom: "10px",
            cursor: "pointer",
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        />

        <Typography fontSize={"1.4rem"} mb={"20px"} fontWeight={"bold"}>
          Nueva Carpeta
        </Typography>

        {isLoading ? (
          <Stack>
            <Loader
              type="Bars"
              color={colorPallette.secondary}
              height={40}
              width={40}
            />
            <span>Cargando ...</span>
          </Stack>
        ) : (
          <form onSubmit={handleSubmit(onSaveFolder)}>
            <Stack>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "left",
                  color: colorPallette.primary,
                }}
              >
                Nombre de la carpeta:
              </Typography>
              <Stack pb={"20px"}>
                <Controller
                  name="folderName"
                  control={control}
                  rules={{
                    required: "El nombre de la carpeta es obligatorio.",
                  }}
                  render={({ field }) => (
                    <TextareaAutosize
                      {...field}
                      style={{
                        width: "100%",
                        minHeight: "30px",
                        alignSelf: "start",
                        margin: "10px 0 0",
                        fontSize: "0.9rem",
                        padding: "15px 0 0 15px",
                        border: "1px solid",
                        borderColor: errors.folderName
                          ? colorPallette.red
                          : colorPallette.primary,
                        borderRadius: "5px",
                        color: colorPallette.dark,
                      }}
                    />
                  )}
                />

                {errors.folderName && (
                  <Typography
                    sx={{ color: "red", fontSize: "1rem", marginTop: "5px" }}
                  >
                    {errors.folderName.message}
                  </Typography>
                )}
              </Stack>

              <Box
                component="button"
                type="submit"
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  justifyContent: "center",
                  minHeight: "50px",
                  borderRadius: "9px",
                  bgcolor: colorPallette.primary,
                  cursor: "pointer",
                  transition: "all 0.12s",
                  "&:hover": {
                    bgcolor: colorPallette.dark,
                  },
                  border: "none",
                }}
              >
                <FontAwesomeIcon icon={faPlus} color={colorPallette.white} />
                <Typography color={colorPallette.white}>
                  Crear Carpeta
                </Typography>
              </Box>
            </Stack>
          </form>
        )}
      </Stack>
    </Modal>
  );
};

export default NewFolderModal;
