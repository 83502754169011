import React, { useState } from "react";
import { Box } from "@mui/material";
import DashboardSectionTitle from "../DashboardSectionTitle/DashboardSectionTitle";
import { useAuth0 } from "@auth0/auth0-react";
import EditUserModal from "../../../uiComponents/EditUserModal/EditUserModal";
import { APIPostWithError } from "../../../Services/authenticated";
import "./UsersManagement.styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyUser,
  setAllCompanyUsers,
} from "../../../redux/slices/masterData";
import UsersManagementTable from "../../../components/Tables/UsersManagement/UsersManagementTable";

const UsersManagement: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const users = useSelector((state: any) => state.masterData.allCompanyUsers);

  const handleEditClick = (user: any) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedUser(null);
  };

  const handleSaveChanges = async (updatedUser: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const body = {
        userId: updatedUser.userId,
        role: updatedUser.role,
        documentShare: updatedUser.documentShare,
      };

      const response = await APIPostWithError(
        "/users/update-user-role-access",
        accessToken,
        body
      );

      if (response.userUpdated) {
        const updatedUsers = users.map((user: CompanyUser) =>
          user.userId === updatedUser.userId
            ? {
                ...user,
                role: updatedUser.role,
                documentShare: updatedUser.documentShare,
              }
            : user
        );

        dispatch(setAllCompanyUsers(updatedUsers));

        handleCloseModal();
      }
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      handleCloseModal();
    }
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      const accesToken = await getAccessTokenSilently();

      const body = {
        userId: userId,
        role: "user",
        companyId: "00000",
        documentShare: false,
      };

      const response = await APIPostWithError(
        "/users/remove-user-company",
        accesToken,
        body
      );

      if (response.userRestricted) {
        const newUsers = users.filter(
          (user: CompanyUser) => user.userId !== userId
        );

        dispatch(setAllCompanyUsers(newUsers));
      } else {
        console.error("Error al restringir el acceso del usuario");
      }
    } catch (error) {
      console.error("Error al eliminar usuario:", error);
    }
  };

  const currentUsers = users
    ? users.filter((user: CompanyUser) => {
        return !user.internalUser;
      })
    : [];

  return (
    <Box className="animate">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DashboardSectionTitle title="Administre los Usuarios de su Empresa" />
      </Box>

      <p className="dashboard-message">
        <i className="fas fa-play secondary margin-r" />
        <>
          Mostrando{" "}
          <span className="alarms-length">{users ? users.length : 0} </span>
          usuarios
        </>
      </p>

      <UsersManagementTable
        documents={currentUsers}
        onEditUser={handleEditClick}
        onDeleteUser={handleDeleteUser}
      />

      {isEditModalOpen && selectedUser && (
        <EditUserModal
          user={selectedUser}
          isOpen={isEditModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveChanges}
        />
      )}
    </Box>
  );
};

export default UsersManagement;
