import React from "react";

type DashboardSectionTitleProps = {
  title: string;
};

const DashboardSectionTitle: React.FC<DashboardSectionTitleProps> = ({
  title,
}) => {
  return (
    <div className="animate">
      <h2 className="a-left" style={{ fontSize: "1.5rem" }}>
        <i className="fas fa-play primary margin-r"></i>
        {title}
      </h2>
    </div>
  );
};

export default DashboardSectionTitle;
