import React, { useEffect } from "react";
import Navbar from "./screens/Navbar/Navbar";

import { connect } from "react-redux";
import { setAnswers } from "./redux/answers/answers.actions";

import { Route, useLocation } from "react-router-dom";

import Empresas from "./screens/Empresas/Empresas";

import DocumentCreator from "./screens/DocumentCreator/DocumentCreator";
import TestDocCreator from "./screens/DocumentCreator/TestDocCreator";
import Pago from "./screens/Pago/Pago";
import DocumentSelector from "./screens/DocumentSelector/DocumentSelector";
import SessionRedirect from "./screens/SectionRedirect/SectionRedirect";
import Profile from "./Authentication/Profile/Profile";
import LoginRedirect from "./Authentication/LoginRedirect/LoginRedirect";
import PaymentError from "./screens/PaymentError/PaymentError";

import * as ContratoDeAlquilerQuestions from "./data/ContratoDeAlquilerQuestions";
import * as ContratoDeServiciosQuestions from "./data/ContratoServicioQuestions";
import * as ContratoDeTrabajoAPlazoFijo from "./data/ContratoTrabajoPlazoFijoQuestions";
import * as ContratoDePrestamoDeDineroQuestions from "./data/ContratoPrestamodeDineroQuestions";
import * as ContratoDeAlquilerTemporalQuestions from "./data/ContratoAlqTemporalQuestions";
import * as NdaQuestions from "./data/NdaQuestions";
import * as TestQuestions1 from "./data/TestQuestions1";
import * as TestQuestions2 from "./data/TestQuestions2";
import * as TestQuestions3 from "./data/TestQuestions3";
import * as TestQuestions4 from "./data/TestQuestions4";
import * as TestQuestions5 from "./data/TestQuestions5";
import * as TestQuestions6 from "./data/TestQuestions6";
import * as TestQuestions7 from "./data/TestQuestions7";
import * as ComodatoQuestions from "./data/ComodatoQuestions";
import * as PagareQuestions from "./data/PagareQuestion";
import * as ReservadeLocacionQuestions from "./data/ReservadeLocacionQuestions";
import * as ReciboQuestions from "./data/ReciboQuestions";
import * as Questions from "./data/questionsExporter";

import ErrorScreen from "./screens/ErrorScreen/ErrorScreen";
import EmailVerification from "./Authentication/EmailVerification/EmailVerification";
import SupportConfig from "./Authentication/SupportConfig/SupportConfig";
import PrivacyPolicy from "./screens/Policies/PrivacyPolicy";
import TermsAndConditions from "./screens/Policies/TermsConditions";
import LegalDisclaimer from "./screens/Policies/LegalDisclamimer";
import Contact from "./screens/AboutUs/Contact/Contact";
import SubscribeScreen from "./screens/Suscription/SubscribeScreen/SubscribeScreen";

import DataDownloader from "./DataDownloader/DataDownloader";
import DataUploader from "./DataDownloader/DataUploader";

import CommonComponentRender from "./screens/CommonComponentRender/CommonComponentRender";
import CompanyDocumentCreator from "./screens/CompanyDocumentCreator/CompanyDocumentCreator";
import CompanyDocumentGenerator from "./screens/DocumentCreator/Download/CompanyDocumentGenerator/CompanyDocumentGenerator";
import DataUpdater from "./DataDownloader/DataUpdater";
import TestDocumentGenerator from "./screens/DocumentCreator/Download/TestDocumentGenerator/TestDocumentGenerator";
import DocxEditorMain from "./components/DocxEditor/DocxEditorMain";
import InternalGenerator from "./app/InternalGenerator/InternalGenerator";
import GeneratorForm from "./app/GeneratorForm/GeneratorForm";
import InternalDocumentGenerator from "./app/InternalDocumentGenerator/InternalDocumentGenerator";
import CompanyDocumentUploader from "./app/Backoffice/CompanyDocumentUploader";
import CompanyDocumentUpdater from "./app/Backoffice/CompanyDocumentUpdater";
import HomeTop from "./screens/Home/HomeTop";
import TrustedCompanies from "./screens/Home/TrustedCompanies/TrustedCompanies";
import ContractLifecycle from "./screens/Home/ContractLifecycle";
import BenefitCards from "./screens/Home/BenefitsCards";
import { ProductTour } from "./screens/ProductTour/ProductTour";
import { ClientsCarousel } from "./screens/Home/ClientsCarousel";
import { YoutubeVideoSection } from "./screens/Home/YoutubeVideoSection";
import Dashboard from "./screens/Dashboard";
import Footer from "./screens/Footer/Footer";
import DocumentStarter from "./app/InternalDocumentGenerator/DocumentStarter";

function App({ setAnswers }) {
  const location = useLocation();

  useEffect(() => {
    if (sessionStorage.answers) {
      const sessionAnswers = JSON.parse(sessionStorage.answers);
      setAnswers(sessionAnswers);
    }
  }, [setAnswers]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Navbar />
      <div className="rest-of-app">
        <Route exact path="/">
          {/* <TopCard /> */}
          <HomeTop />
          <TrustedCompanies />
          <ClientsCarousel />
          <ContractLifecycle />
          <YoutubeVideoSection />
          <BenefitCards />
          <Footer />
        </Route>
        <Route path="/docx-editor">
          <DocxEditorMain />
        </Route>
        <Route path="/document-selector">
          <DocumentSelector />
        </Route>
        <Route path="/suscripcion">
          <Empresas />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        {/* This section is for each document */}
        <Route path="/company-document-creator">
          <CompanyDocumentCreator />
        </Route>
        <Route path="/contrato-de-servicios">
          <DocumentCreator
            data={ContratoDeServiciosQuestions.ContratoDeServicios}
          />
        </Route>
        <Route path="/contrato-de-alquiler">
          <DocumentCreator
            data={ContratoDeAlquilerQuestions.ContratoDeAlquiler}
          />
        </Route>
        <Route path="/contrato-de-trabajo">
          <DocumentCreator
            data={ContratoDeTrabajoAPlazoFijo.ContratoDeTrabajoAPlazoFijo}
          />
        </Route>
        <Route path="/contrato-de-prestamo-de-dinero">
          <DocumentCreator
            data={
              ContratoDePrestamoDeDineroQuestions.ContratoDePrestamoDeDinero
            }
          />
        </Route>
        <Route path="/contrato-de-alquiler-temporal">
          <DocumentCreator
            data={
              ContratoDeAlquilerTemporalQuestions.ContratoDeAlquilerTemporal
            }
          />
        </Route>
        <Route path="/nda">
          <DocumentCreator data={NdaQuestions.NDA} />
        </Route>
        <Route path="/comodato">
          <DocumentCreator data={ComodatoQuestions.Comodato} />
        </Route>
        <Route path="/pagare">
          <DocumentCreator data={PagareQuestions.Pagare} />
        </Route>
        <Route path="/reserva-de-locacion">
          <DocumentCreator
            data={ReservadeLocacionQuestions.ReservaDeLocacion}
          />
        </Route>
        <Route path="/recibo">
          <DocumentCreator data={ReciboQuestions.Recibo} />
        </Route>
        <Route path="/contrato-de-alquiler-vieja-ley">
          <DocumentCreator data={Questions.contratoDeAlquilerNormal} />
        </Route>
        {/* //
      //
      //
      //
      //
      // */}
        <Route path="/test1">
          <TestDocCreator data={TestQuestions1.Test1} />
        </Route>
        <Route path="/test2">
          <TestDocCreator data={TestQuestions2.Test2} />
        </Route>
        <Route path="/test3">
          <TestDocCreator data={TestQuestions3.Test3} />
        </Route>
        <Route path="/test4">
          <TestDocCreator data={TestQuestions4.Test4} />
        </Route>
        <Route path="/test5">
          <TestDocCreator data={TestQuestions5.Test5} />
        </Route>
        <Route path="/test6">
          <TestDocCreator data={TestQuestions6.Test6} />
        </Route>
        <Route path="/test7">
          <TestDocCreator data={TestQuestions7.Test7} />
        </Route>

        <Route path="/mal">
          <div>Error, por favor reintentar</div>
        </Route>
        {/* This is a route for payments */}
        <Route path="/pago">
          <Pago />
        </Route>
        {/* This is the route for downloads */}
        <Route path="/download-test">
          <TestDocumentGenerator />
        </Route>
        <Route path="/company-document-download">
          <CompanyDocumentGenerator />
        </Route>

        {/* This sections are extremelu important for redirecting users */}
        <Route path="/redirect" component={SessionRedirect} />
        <Route path="/redirect/:id" component={SessionRedirect} />
        {/* auth related routes */}
        <Route path="/profile" component={Profile} />
        <Route path="/login-redirect" component={LoginRedirect} />
        <Route path="/email_verification" component={EmailVerification} />
        <Route path="/support-config" component={SupportConfig} />
        {/* error Screens */}
        <Route path="/error" component={ErrorScreen} />
        {/* payment error Screens */}
        <Route path="/payment-error" component={PaymentError} />
        {/* userSuscription */}
        {/* // esta ruta debe permanecer comentada */}
        {/* <Route path="/subscribe-user" component={SubscribeUser} /> */}
        <Route path="/subscribe-contact" component={SubscribeScreen} />
        {/* policies */}
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/legal-disclaimer" component={LegalDisclaimer} />
        {/* product tour */}
        <Route path="/productTour" component={ProductTour} />
        {/* contact */}
        <Route path="/contacto" component={Contact} />
        <Route path="/data-downloader" component={DataDownloader} />
        <Route path="/data-uploader" component={DataUploader} />
        <Route path="/data-updater" component={DataUpdater} />
        <Route path="/internal-generator" component={InternalGenerator} />

        <Route path="/generator-form" component={GeneratorForm} />
        <Route
          path="/backoffice-company-documnent-uploader"
          component={CompanyDocumentUploader}
        />
        <Route
          path="/backoffice-company-documnent-updater"
          component={CompanyDocumentUpdater}
        />

        <Route
          path="/internal-document-generator"
          component={InternalDocumentGenerator}
        />

        <Route path="/document-starter" component={DocumentStarter} />
      </div>
      <CommonComponentRender />
      {/* {path !== "/internal-document-generator" && <Footer />} */}
    </div>
  );
}

const mapDispatchToProps = {
  setAnswers,
};

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
