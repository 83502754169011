export const getImageName = (format: string | undefined) => {
  if (format === "pdf") {
    return "pdf2-icon.png";
  }
  if (format === "xls" || format === "xlsx") {
    return "excel-icon.png";
  }
  if (format === "ppt" || format === "pptx") {
    return "ppt-icon.png";
  }
  if (
    format === "png" ||
    format === "jpg" ||
    format === "jpeg" ||
    format === "heic"
  ) {
    return "pic-icon.png";
  }
  return "word-icon.png";
};
