import React, { useEffect, useState } from "react";
import NewElectronicSignatureScreen from "./NewElectronicSignatureScreen/NewElectronicSignatureScreen";
import ElectronicSignatureForm from "./ElectronicSignatureForm/InputTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setShouldESign } from "../../../redux/slices/EditorDataSlice";
import { Document } from "../../../Types/Document.types";

import "./styles.css";
import { AppDispatch, RootState } from "../../../redux/store";

type ElectronicSignatureProps = {
  documents: Document[];
  searchDocuments: () => void;
  company: string;
};

const ElectronicSignature: React.FC<ElectronicSignatureProps> = ({
  documents,
  searchDocuments,
  company,
}) => {
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [currentSignDocument, setCurrentSignDocument] =
    useState<Document | null>(null);

  const dispatch: AppDispatch = useDispatch();
  const { document, shouldESign } = useSelector(
    (state: RootState) => state.editorData
  );

  const setEsignDocumentData = (document: Document) => {
    setCurrentSignDocument(document as Document);
    setCurrentSection(1);
  };

  useEffect(() => {
    if (shouldESign) {
      dispatch(setShouldESign(false));
      setEsignDocumentData(document);
    }
  }, []);

  return (
    <div className="electronic-signature-wrapper animate">
      {currentSection === 0 && (
        <NewElectronicSignatureScreen
          documents={documents}
          setEsignDocumentData={setEsignDocumentData}
          company={company}
        />
      )}
      {currentSection === 1 && (
        <div
          className="electronic-signature-cancel b-light-red"
          onClick={() => {
            setCurrentSection(0);
            setCurrentSignDocument(null);
          }}
          style={{ cursor: "pointer", marginTop: 40 }}
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className="margin-icon"
          />
          Cancelar
        </div>
      )}
      {currentSection === 1 && currentSignDocument && (
        <ElectronicSignatureForm
          document={currentSignDocument}
          company={company}
        />
      )}
    </div>
  );
};

export default ElectronicSignature;
