import { atom } from "jotai";
import { getDefaultStore } from "jotai";
import { Document, EsignatureType } from "../redux/slices/masterData";
import { NewSigner } from "../Types/Signer.types";

type CommonComponentAtomType = {
  showNewFolderModal: boolean;
  showFolderModal: boolean;
  showDocumentDetailModal: boolean;
  showLoadingScreen: boolean;
  currentDocument: Document | null;
  showSignatureStatusModal: boolean;
  currentSignature: EsignatureType | null;
  showSignerEmailModal: boolean;
  currentSigner: NewSigner | null;
};

export const initialCommonComponentAtom: CommonComponentAtomType = {
  showNewFolderModal: false,
  showFolderModal: false,
  showDocumentDetailModal: false,
  showLoadingScreen: false,
  currentDocument: null,
  showSignatureStatusModal: false,
  currentSignature: null,
  showSignerEmailModal: false,
  currentSigner: null,
};

export const commonComponentAtom = atom<CommonComponentAtomType>(
  initialCommonComponentAtom
);

const store = getDefaultStore();

export const resetCommonComponent = () => {
  store.set(commonComponentAtom, initialCommonComponentAtom);
};

export const setCommonComponents = (params: any) => {
  store.set(commonComponentAtom, { ...initialCommonComponentAtom, ...params });
};
