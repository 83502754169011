import React, { useEffect, useState } from "react";
import { APIGet } from "../../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "react-loader-spinner";
import { Folder } from "../Folder/Folder";
import { useAtomValue } from "jotai";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { colorPallette } from "../../../../Common/theme";
import { faRectangleXmark } from "@fortawesome/free-regular-svg-icons";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../../jotai/commonComponentAtom.jotai";
import {
  editDocument,
  editReduxDocument,
} from "../../../../Utils/documentMethods";

interface FolderData {
  folderId: string;
  folderName: string;
}

interface DocumentData {
  fileName?: string;
  folder?: string;
}

const FolderModal: React.FC = () => {
  const [folders, setFolders] = useState<FolderData[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { showFolderModal, currentDocument } =
    useAtomValue(commonComponentAtom);
  const { getAccessTokenSilently } = useAuth0();

  const getFolders = async () => {
    try {
      const accesToken = await getAccessTokenSilently();
      const allFolders = await APIGet(
        "/folders/get-company-folders",
        accesToken
      );

      if (allFolders.folders && allFolders.folders.length > 0) {
        const sortedArray = allFolders.folders.sort((a: any, b: any) => {
          return b.folderId - a.folderId;
        });
        setFolders(sortedArray);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      throw e;
    }
  };

  const closeModal = () => {
    resetCommonComponent();
  };

  const onChangeFolder = async (newFolderId: any) => {
    setIsLoading(true);
    const payload = {
      ...(currentDocument as DocumentData),
      folder: newFolderId,
    };

    payload.folder = newFolderId;
    try {
      const accessToken = await getAccessTokenSilently();

      await editDocument(payload, accessToken, editReduxDocument);

      // await APIPost("/doc-ref/edit-document", accessToken, payload);
      // window.location.reload();
      resetCommonComponent();
    } catch (e) {
      setIsLoading(false);
      alert("Error, intentelo nuevamente");
    }
  };

  useEffect(() => {
    getFolders();
  }, []);

  const handleOpenNewFolderModal = () => {
    setCommonComponents({
      showNewFolderModal: true,
      currentDocument: currentDocument,
    });
  };

  return (
    <Modal open={showFolderModal} onClose={closeModal}>
      <Stack
        sx={{
          backgroundColor: "white",
          position: "fixed",
          top: "7%",
          left: "50%",
          transform: "translateX(-50%)",
          width: "500px",
          height: "600px",
          borderRadius: "6px",
          padding: "30px 20px",
        }}
      >
        <FontAwesomeIcon
          onClick={closeModal}
          icon={faRectangleXmark}
          style={{
            fontSize: "1.4rem",
            color: colorPallette.primary,
            marginBottom: "10px",
            cursor: "pointer",
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        />

        <Typography
          sx={{
            fontSize: "1.3rem",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          Mover Documento
        </Typography>
        <Typography
          color={colorPallette.primary}
          sx={{ margin: "20px 0 30px", fontStyle: "italic" }}
        >
          {currentDocument?.fileName}
        </Typography>
        {isLoading && (
          <Stack sx={{ marginTop: "30px", marginBottom: "20px" }}>
            <Loader
              type="Bars"
              color={colorPallette.secondary}
              height={60}
              width={60}
            />
            <span>Cargando ...</span>
          </Stack>
        )}
        {!isLoading && (
          <Stack>
            <Box
              onClick={handleOpenNewFolderModal}
              sx={{
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                bgcolor: colorPallette.primary,
                borderRadius: "9px",
                cursor: "pointer",
                display: "flex",
                height: "50px",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                transition: "all 0.12s",
                mb: "20px",
                "&:hover": {
                  bgcolor: colorPallette.dark,
                },
              }}
            >
              <FontAwesomeIcon icon={faPlus} color={colorPallette.white} />
              <Typography color={colorPallette.white}>Nueva carpeta</Typography>
            </Box>

            <Typography>Mover a:</Typography>
            <Stack
              sx={{
                height: "320px",
                overflow: "scroll",
                mt: "20px",
                gap: 1,
              }}
            >
              {folders &&
                folders.map((folder) => {
                  return (
                    <Folder
                      key={folder.folderId}
                      name={folder.folderName}
                      onClick={() => {
                        onChangeFolder(folder.folderId);
                      }}
                    />
                  );
                })}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default FolderModal;
