import { Box, Typography } from "@mui/material";
import { ProfilePicAndName } from "../../../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import {
  findUserNameById,
  findUserPhotoUrl,
} from "../../../../../Utils/commonTableActions";
import { colorPallette } from "../../../../../Common/theme";

type CreatorFieldProps = {
  document: any;
};

const CreatorField: React.FC<CreatorFieldProps> = ({ document }) => {
  const userName = findUserNameById(document?.userId);
  const profileImage = findUserPhotoUrl(document?.userId);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: "0 9px 0 9px",
        padding: 1,
        marginBottom: 7,
        minWidth: 200,
        bgcolor: colorPallette.secondary,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "left",
          transform: "scale(0.9)",
        }}
      >
        <ProfilePicAndName image={profileImage} name={userName} />
      </Box>
    </Box>
  );
};

export default CreatorField;
