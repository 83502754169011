import React from "react";
import { useSelector } from "react-redux";
import { User } from "../../../Types/User.types";

import "./styles.css";

type DashboardSummaryProps = {
  user: User | undefined;
  companyDocumentsLength: number | null;
  documentsLength: number | null;
  spaceUsage: number;
};

const DashboardSummary: React.FC<DashboardSummaryProps> = ({
  user,
  companyDocumentsLength,
  documentsLength,
  spaceUsage,
}) => {
  const { company } = useSelector((state: any) => state.masterData);

  const calculateWidth = (space: number) => {
    const Mb = Math.round(spaceUsage / 10000) / 100;
    const toLimit = Mb / 1000;
    if (toLimit > 0.1) return toLimit;
    return 0.1;
  };

  return (
    <div className="animate">
      {company && (
        <div>
          <div className="dashboard-summary">
            <div className="dashboard-summary-box ">
              <h4 className="primary">{company.companyName}</h4>
              <p>Empresa</p>
            </div>
            <div className="dashboard-summary-box ">
              <h4 className="primary">{documentsLength || "0"}</h4>
              <p>Documentos Generados</p>
            </div>
            <div className="dashboard-summary-box ">
              <h4 className="primary">{companyDocumentsLength || "0"}</h4>
              <p>Plantillas Automatizadas</p>
            </div>
            <div className="dashboard-summary-box cloud-space-used ">
              <p className="margin-v">
                Espacio Cloud utilizado: <br />
                <span className="primary">
                  {spaceUsage ? Math.round(spaceUsage / 10000) / 100 : 0} MB /
                  1000 MB
                </span>
              </p>
              <div
                className="dashboard-summary-line-back"
                style={{ width: 200 }}
              >
                <div
                  className="dashboard-summary-line-inner b-primary"
                  style={{ width: 200 * calculateWidth(spaceUsage) }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardSummary;
