import React, { useState } from "react";
import {
  faPencil,
  faLink,
  faTrash,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../../../../../uiComponents/ActionButton/ActionButton";
import "./SignerActions.styles.css";
import {
  closeModal,
  modalOpen,
  setAppError,
  setLoading,
} from "../../../../../Utils/modalMethods";
import { APIPostWithError } from "../../../../../Services/authenticated";
import { setCommonComponents } from "../../../../../jotai/commonComponentAtom.jotai";
import { Box } from "@mui/material";

type Props = {
  data: any;
  signerCount: number;
};

export const deleteOneSigner = async (data: any, accessToken: any) => {
  try {
    setLoading(true);
    const res = await APIPostWithError(
      "/electronic-signature/delete-signer",
      accessToken,
      {
        signerToken: data.token,
      }
    );
    if (res.signerDeleted === true) {
      setTimeout(() => {
        modalOpen({
          successModal: {
            show: true,
            message: "Firmante Eliminado Exitosamente",
            submessage: "Ya no aparecerá en el documento.",
            disclaimer: "",
            shouldReload: false,
          },
        });
      }, 500);
      closeModal();
    } else {
      setTimeout(() => {
        setAppError();
      }, 50);
      closeModal();
    }
  } finally {
    setLoading(false);
  }
};

type ActionButtonType = {
  icon: IconDefinition;
  label: string;
  onClick: () => void;
  condition: boolean;
};

const SignerActions: React.FC<Props> = ({ data, signerCount }) => {
  const [showToast, setShowToast] = useState<boolean>(false);

  const openSignerEmailModal = () => {
    closeModal();
    setCommonComponents({
      showSignerEmailModal: true,
      currentSigner: data,
    });
  };

  const copySignLink = async () => {
    const signatureUrl = data.sign_url;

    try {
      await navigator.clipboard.writeText(signatureUrl);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    } catch (error) {
      console.error("Error al copiar el enlace:", error);
      setAppError("Error al copiar el link");
    }
  };

  const openDeleteConfirmModal = () => {
    closeModal();
    modalOpen({
      confirmModal: {
        show: true,
        message: "Confirme eliminación",
        submessage: "¿Seguro desea eliminar a este firmante?",
        disclaimer: "*No se podrá deshacer",
        isAsync: true,
        confirmMethodName: "deleteOneSigner",
        data: data,
      },
    });
  };

  const actions: ActionButtonType[] = [
    {
      icon: faPencil,
      label: "Editar email",
      onClick: openSignerEmailModal,
      condition: data.status !== "signed",
    },
    {
      icon: faLink,
      label: "Copiar link para firmar",
      onClick: copySignLink,
      condition: true,
    },
    {
      icon: faTrash,
      label: "Eliminar firmante",
      onClick: openDeleteConfirmModal,
      condition: signerCount > 1 && data.status !== "signed",
    },
  ];

  return (
    <div className="signer-actions-wrapper">
      {actions
        .filter((action) => action.condition)
        .map((action, index) => (
          <ActionButton
            key={index}
            icon={action.icon}
            label={action.label}
            onClick={action.onClick}
          />
        ))}
      {showToast && (
        <Box className="toast b-primary white bold" sx={{ maxWidth: "700px" }}>
          Link para firmar copiado! Envíelo al firmante y podrá firmar
          accediendo al link.
        </Box>
      )}
    </div>
  );
};

export default SignerActions;
