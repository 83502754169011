import React from "react";
import { Box } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

const LoadingModal = () => {
  return (
    <Box
      sx={{
        zIndex: 9001,
        backgroundColor: "white",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // animation: "modal-animation 0.5s ease-in",
        // "@keyframes modal-animation": {
        //   "0%": { opacity: 0 },
        //   "100%": { opacity: 1 },
        // },
      }}
    >
      <Box
        sx={{
          animation: "modal-children-animation 0.1s ease-in",
          "@keyframes modal-children-animation": {
            "0%": { opacity: 0 },
            "100%": { opacity: 1 },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "120px",
            marginBottom: "120px",
            "@keyframes bolt-animation": {
              "0%": {
                opacity: 0.2,
                transform: "translateY(0) scale(0.4) rotate(0deg)",
              },
              "50%": {
                opacity: 1,
                transform: "translateY(-40px) scale(1.1) rotate(180deg)",
              },
              "100%": {
                opacity: 0.2,
                transform: "translateY(0) scale(0.4) rotate(360deg)",
              },
            },
          }}
        >
          <FontAwesomeIcon
            icon={faBolt}
            color={colorPallette.dark}
            fontSize={"3rem"}
            style={{
              animation: "bolt-animation 2.2s ease-in-out infinite",
            }}
          />
          {/* <Typography
            sx={{
              marginTop: "16px",
              // fontWeight: "500",
              color: colorPallette.dark,
              animation: "cargando-animation 2s ease-in infinite",
              fontSize: "0.8rem",
              "@keyframes cargando-animation": {
                "0%": { opacity: 0.12 },
                "50%": { opacity: 1 },
                "100%": { opacity: 0.12 },
              },
            }}
          >
            Cargando ...
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingModal;
